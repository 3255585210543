export class OAuthProvider {
  issuer: string;
  clientId: string;
  scope: string;
  label: string;

  constructor(raw: ISystemInfo) {
    this.issuer = raw.oauthInfo.issuer;
    this.clientId = raw.oauthInfo.clientId;
    this.scope = raw.oauthInfo.scope;
    this.label = raw.oauthInfo.label;
  }
}

export interface IOAuthProvider {
    enabled: boolean;
    issuer: string;
    clientId: string;
    scope: string;
    label: string;
}

export interface ISystemInfo {
  buildDate: string;
  version: string;
  weightUnit: string;
  oauthInfo: IOAuthProvider;
}
