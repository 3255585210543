import { Component, Input, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tippy-dropdown',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TippyDropdownComponent)
  }],
  templateUrl: './tippy-dropdown.component.html',
  styleUrls: ['./tippy-dropdown.component.scss']
})
export class TippyDropdownComponent<T> implements ControlValueAccessor {
  @Input() items: T[] = [];
  @Input() icon: string = '';
  @Input() itemGroup: string = '';
  @Input() showTitle: boolean = true;
  @Input() template: TemplateRef<undefined> | null = null;

  disabled = false;
  touched = false;
  selectedItem!: T;
  onChange: (model: T) => void = () => { };
  onTouched: () => void = () => { };
  tippyOptions = {
    theme: 'dropdown',
    interactive: true,
    trigger: 'click',
    appendTo: document.body,
    placement: 'bottom-start',
    offset: [0, 6],
  };

  selectItem(item: T) {
    if (!this.touched && this.selectedItem !== item) {
      this.onTouched();
      this.touched = true;
    }

    this.selectedItem = item;
    this.onChange(this.selectedItem);
  }

  registerOnChange(fn: (model: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(model: T): void {
    this.selectedItem = model;
  }
}
