import {Injectable} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  constructor(private oauthService: OAuthService) {}

  // overrides the default token remover and manages oauth logout for unauthorized requests (keeping oauth2 concerns out of the authmodule library)
  removeToken() {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');

    if (this.oauthService.hasValidAccessToken()) {
      this.oauthService.logOut();
    }
  }
}
