import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SystemInfoInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('system/info') && req.method === 'GET') {
      return next.handle(req).pipe(
        catchError(error => {
          console.error('Error fetching system info', error);
          return of(null);
        })
      );
    }

    return next.handle(req);
  }
}
