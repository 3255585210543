import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthError, AuthService} from '@innobile/authmodule';
import {OAuthService} from 'angular-oauth2-oidc';
import {CompanyUser} from '../models/CompanyUser';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SharedVariablesService} from '../services/shared-variables.service';
import {OAuthProvider} from '../models/oauth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private errMsg = $localize`:@@login.error:Invalid e-mail/password`;
  private redirect: string;

  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });

  oauthProvider?: OAuthProvider;
  displayOauthError: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private oauthService: OAuthService,
    private sharedVariablesService: SharedVariablesService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('redirect')) {
        this.redirect = params.redirect;
      }
    });

    if (this.sharedVariablesService.hasVar('oauthProvider')) {
      this.oauthProvider = this.sharedVariablesService.getVar('oauthProvider');
      if (this.oauthProvider) {
        this.initOAuth();
      }
    }
  }

  login() {
    if (this.formGroup.valid) {
      this.auth.logIn({
        email: this.formGroup.controls.email.value,
        password: this.formGroup.controls.password.value
      }).subscribe(() => {
        const redirect = this.redirect ? this.redirect : '';
        this.redirect = null;
        this.router.navigateByUrl(redirect);
      }, error => {
        console.error(error);
        if (error instanceof AuthError) {
          this.formGroup.setErrors({form: this.errMsg});
        }
      });
    }
  }

  oauthLogin(): void {
    if (this.oauthProvider) {
      this.initOAuth();
      this.oauthService.initCodeFlow('/login');
    }
  }

  private initOAuth() {
    if (this.oauthProvider) {
      this.oauthService.configure({
        issuer: this.oauthProvider.issuer,
        clientId: this.oauthProvider.clientId,
        scope: this.oauthProvider.scope,
        redirectUri: window.location.origin + '/login',
        responseType: 'code',
        strictDiscoveryDocumentValidation: false,
        showDebugInformation: !environment.production,
      });
      this.oauthService
        .loadDiscoveryDocumentAndTryLogin({
          onLoginError: (err) => {
            console.log(err);
            this.displayOauthError = true;
          },
        })
        .then(() => {
          if (this.oauthService.hasValidAccessToken()) {
            this.auth
              .loginWithOAuthToken(
                this.oauthService.getAccessToken(),
                'oauth2/login'
              )
              .subscribe({
                next: (user: CompanyUser) => {
                  if (user !== null) {
                    this.router.navigateByUrl(this.redirect || '');
                    this.redirect = '';
                  }
                },
                error: (error: HttpErrorResponse) => {
                  console.error(error);
                  this.formGroup.setErrors({ oauthError: true });
                },
              });
          }
        });
    }
  }
}
