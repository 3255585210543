<section class="content">
  <div [class.has-error]="formGroup.hasError('form') && formGroup.dirty" class="logo">
    <img src="assets/img/logo.svg" alt="logo">
  </div>
  <form *ngIf="!displayOauthError" (ngSubmit)="login()" [formGroup]="formGroup" class="input-container">
    <div class="title" i18n="Login title">Sign in with Pozi account</div>
    <div class="input-row">
      <label for="email" i18n="Login e-mail label">E-mail</label>
      <input [formControlName]="'email'" id="email" name="email" type="email">
    </div>
    <div class="input-row">
      <label for="password" i18n="Login password label">Password</label>
      <input [formControlName]="'password'" id="password" name="password" type="password">
    </div>

    <div *ngIf="formGroup.hasError('form') && formGroup.dirty" class="error">
      <i class="material-icons">error_outline</i>
      <span>{{errMsg}}</span>
    </div>

    <button [disabled]="formGroup.invalid" class="login-button" type="submit">
      <i class="material-icons">login</i>
      <span i18n="Login form submit">Login</span>
    </button>
  
    <div *ngIf="oauthProvider" class="oauth">
      <div class="title" i18n="OAuth login title">Sign in with corporate account</div>
      <button type="button" class="oauth-provider" (click)="oauthLogin()">
        <i class="material-icons">login</i>
        <span>{{ oauthProvider.label }}</span>
      </button>
    </div>
  </form>

  <div *ngIf="displayOauthError">
    <div class="title" i18n="OAuth login title">Sign in with corporate account</div>
    <div class="error">
      <i class="material-icons">error_outline</i>
      <span i18n>Sign in with corporate account was not successful<br/>Please contact your IT administrator</span>
    </div>
    <button class="back" (click)="displayOauthError = false" type="button">
      <i class="material-icons">arrow_back</i>
      <span i18n>Back</span>
    </button>
  </div>

  <app-language-switch></app-language-switch>
</section>
