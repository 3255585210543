<button class="dropdown-button" [tippy]="listTemplate" [tippyOptions]="tippyOptions"
  [templateContext]="{items, selectedItem}" [disabled]="disabled">
  <i class="material-icons">{{ icon }}</i>
  <ng-container *ngIf="selectedItem && showTitle" [ngTemplateOutlet]="template ? template : defTemplate"
    [ngTemplateOutletContext]="{$implicit: selectedItem}">
  </ng-container>
</button>

<ng-template #listTemplate>
  <div class="dropdown-content">
    <span *ngIf="itemGroup" class="dropdown-item-group">{{itemGroup}}</span>
    <ul class="item-list" role="menu">
      <li *ngFor="let item of items; trackBy: item" role="menuitem" tabindex="0" (click)="selectItem(item)" (keydown.enter)="selectItem(item)">
        <i *ngIf="item === selectedItem" class="material-icons">done</i>
        <span *ngIf="item !== selectedItem" class="check-placeholder"></span>
        <ng-container *ngTemplateOutlet="template ? template : defTemplate; context: {$implicit: item}">
        </ng-container>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #defTemplate let-item>
  <span>{{ item }}</span>
</ng-template>